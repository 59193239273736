import { useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";
import { servicesData } from "../../data/servicesData";
import SingleService from "./SingleService/SingleService";
import "./Services.css";

const Services: React.FC = () => {
  const { theme } = useContext(ThemeContext);
  return (
    <>
      {servicesData.length > 0 && (
        <div
          className="services"
          id="services"
          style={{ backgroundColor: theme.secondary }}
        >
          <div className="services-header">
            <h1 style={{ color: theme.primary }}>Services</h1>
          </div>
          <div className="services-body">
            <p style={{ color: theme.tertiary80 }}>
              These are some of the services I offer. Reach out to me if I can
              help you with any!
            </p>
            <div className="services-bodycontainer">
              {servicesData.map((services) => (
                <SingleService
                  key={services.id}
                  id={services.id}
                  title={services.title}
                  icon={services.icon}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Services;
