import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Main, ProjectPage } from "./pages";
import { BackToTop } from "./components";
import ScrollToTop from "./utils/ScrollToTop";
import "./App.css";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    console.log(
      "%c 👋 Bonjour vous cherchez a faire votre site web ?  ",
      "color:blue ;font-size:15px",
    );
    console.log(
      "%c 📝 Contact 👉 kalil.belgoumri@gmail.com ",
      "color:blue ;font-size:15px",
    );
    console.log(
      "%c ⚛️ Developpeur React , NextJs etc ... ",
      "color:blue ;font-size:15px",
    );
  }, []);
  return (
    <div className="app">
      <Router>
        <ScrollToTop />
        <Switch>
          <Route path="/" exact component={Main} />
          <Route path="/projects" exact component={ProjectPage} />
          <Redirect to="/" />
        </Switch>
      </Router>
      <BackToTop />
    </div>
  );
}

export default App;
