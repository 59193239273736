import "./index.css";
import App from "./App";
import ThemeContextProvider from "./contexts/ThemeContext";
import { createRoot } from "react-dom/client";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const container = document.getElementById("root");
if (!container) {
  throw new Error("No root element found");
}

const root = createRoot(container);

root.render(
  <ThemeContextProvider>
    <App />
  </ThemeContextProvider>,
);
