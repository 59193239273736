export const socialsData = {
  github: "https://github.com/kalilBelgoumri",
  // facebook: "https://www.facebook.com/",
  linkedIn: "https://www.linkedin.com/in/kalil-belgoumri-1a5b17203/",
  // instagram: "https://www.instagram.com/",
  // codepen: "https://codepen.io/",
  // twitter: "https://twitter.com/",
  // reddit: "https://www.reddit.com/user/",
  // blogger: "https://www.blogger.com/",
  // medium: "https://medium.com/@",
  // stackOverflow: "https://stackoverflow.com/users/",
  // gitlab: "https://gitlab.com/",
  // youtube: "https://youtube.com/",
};
