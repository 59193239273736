import centurion from "../assets/png/centurion.webp";
import crc from "../assets/png/crc.webp";
import dag from "../assets/png/dag.webp";
import dior from "../assets/png/dior.webp";
import sword from "../assets/png/sword_website.webp";
import webinnova from "../assets/png/webinnova.png";
import maitreDupont from "../assets/png/maitreDupont.png";

export const projectsData = [
  {
    id: 1,
    projectName: "Christian Dior Couture",
    projectDesc:
      "This project revolves around the development and implementation of digital solutions for Christian Dior Couture, aiming to enhance user experience and streamline internal operations for this globally renowned haute couture house.",
    tags: ["React", "NextJs", "CSS", "GraphQL", "Appolo client"],
    // code: "",
    demo: "https://www.dior.com/fr_fr",
    image: dior,
  },
  {
    id: 2,
    projectName: "CRC-INC",
    projectDesc:
      "A showcase for CRC-INC, experts in renovating terraces, buildings, and pergolas. The site highlights their services and craftsmanship.",
    tags: ["WordPress", "CSS"],
    code: "",
    demo: "https://www.crc-inc.ca/",
    image: crc,
  },
  {
    id: 3,
    projectName: "Dag System",
    projectDesc:
      "A comprehensive platform for the Dag System tourism office, offering visitors insights into attractions, events, and local highlights. Designed to enhance the tourist experience.",
    tags: ["NextJs", "CSS", "Material Ui", "Tailwind"],
    // code: "",
    demo: "https://p3dag.duckdns.org/",
    image: dag,
  },
  {
    id: 4,
    projectName: "Centurion securité",
    projectDesc:
      "Developed for 'Centurion Securite', this platform highlights the agency's security services and expertise, serving as their digital front for potential clients.",
    tags: ["React", "NextJs", "CSS", "Tailwind"],
    // code: "",
    demo: "https://www.centurion-securite.fr",
    image: centurion,
  },
  {
    id: 5,
    projectName: "Sword Group",
    projectDesc:
      "At Sword Group, a global IT consulting firm, I aided in crafting and deploying innovative tech solutions to meet client demands, collaborating closely with diverse teams to ensure quality deliverables.",
    tags: ["React", "CSS", "JavaScript", "HTML"],
    // code: "",
    demo: "https://www.sword-group.com/fr/",
    image: sword,
  },
  {
    id: 6,
    projectName: "Webinnova",
    projectDesc:
      "WebInnova offers tailor-made web solutions, specializing in unique website designs and effective SEO to boost your online presence.",
    tags: ["React", "NextJs", "CSS", "Tailwind", "JavaScript", "HTML"],
    // code: "",
    demo: "https://www.webinnova.fr",
    image: webinnova,
  },
  {
    id: 7,
    projectName: "Maître Dupont",
    projectDesc:
      "Maître Dupont's legal website provides professional legal services with a focus on client care. Specializing in a range of legal areas, it offers expert advice, representation, and personalized solutions for every legal need.",
    tags: ["React", "NextJs", "CSS", "Tailwind", "JavaScript", "HTML"],
    demo: "https://advocate-sample.vercel.app/",
    image: maitreDupont,
  },
];
