import { useState } from "react";
import config from "../config/config";

// is this a hook personal for sending email

export const useContactForm = () => {
  const apiKey = config.BREVO_API_KEY;
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [success, setSuccess] = useState<string>("");
  const [errMsg, setErrMsg] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const message = `
  <div style="font-family: Arial, sans-serif; background-color: #f5f5f5; padding: 20px;">
    <div style="background-color: white; padding: 20px; border-radius: 10px; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);">
      <h2 style="color: #333;">Nouveau message depuis le Portfolio</h2>
      <p style="margin-top: 10px; font-size: 16px; color: #555;">
        Nom: ${formData.name}
      </p>
      <p style="font-size: 16px; color: #555;">
        Email: ${formData.email}
      </p>
      <p style="font-size: 16px; color: #555;">
        Message: ${formData.message}
      </p>
    </div>
  </div>
`;

  const HandleContactForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formData.name && formData.email && formData.message) {
      const SibApiV3Sdk = require("sib-api-v3-typescript");
      const apiInstance = new SibApiV3Sdk.TransactionalEmailsApi();

      // Configure API key authorization
      apiInstance.setApiKey(
        SibApiV3Sdk.TransactionalEmailsApiApiKeys.apiKey,
        `${apiKey}`,
      );
      const sendSmtpEmail = {
        to: [{ email: "kalil.belgoumri@gmail.com" }], // Replace with your email address
        sender: {
          name: formData.name,
          email: formData.email,
        },
        subject: "Nouveau message depuis le Portfolio",
        htmlContent: message,
      };

      try {
        await apiInstance.sendTransacEmail(sendSmtpEmail);
        setSuccess("Email sent successfully");
        setFormData({
          name: "",
          email: "",
          message: "",
        });
        setOpen(true);
      } catch (error) {
        console.error("Error sending email:", error);
        setErrMsg("An error occurred while sending the email");
        setOpen(true);
        setSuccess("");
      }
    } else {
      setErrMsg("Please enter all fields");
      setOpen(true);
      setSuccess("");
      setSuccess("");
    }
  };
  return {
    HandleContactForm,
    formData,
    setFormData,
    success,
    errMsg,
    open,
    setOpen,
  };
};
